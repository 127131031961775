<!--
 * @Date: 2023-10-17 15:54:12
 * @LastEditTime: 2024-02-23 19:07:27
 * @Description: 1024-消消乐
-->
<template>
  <div>
    <Viewheader theme="light" ref="navheader" />
    <div class="smearCruch">
      <div class="smearCruch_bg"></div>
      <div class="smearCruch-wrap">
        <div class="smearCruch-title">消消乐</div>
        <div class="smearCruch-cont">
          <ImgUpload
            class="sc-img-upload"
            @success="getResultImg"
            @select="selectPicture"
            @change="handleChange"
            :modelIndex.sync="imgCurrentActive"
            :imgList="exampleDiagram"
            :listScrollTop.sync="listScrollTop"
            uploadText="上传"
          >
            <div class="upload-title" slot="title">
              <img :src="titleIcon" alt="" />图片
            </div>
          </ImgUpload>
          <!-- 等比例放大比图片 -->
          <canvas ref="canvas" id="canvas1" style="display: none"></canvas>
          <div class="logoImg">
            <p>
              模型⽬前仅⽀持png、jpg、jpeg。通过API接口自动化批量处理，请联系我们
            </p>
            <div class="smear-wrap hover-container">
              <canvas id="canvas" class="img-thumbnail"></canvas>
              <div class="logoImgLoad" ref="imageWrapper">
                <!-- 结果图 -->

                <img
                  :src="resultGraphImg"
                  v-if="successImg"
                  class="logoImgResult"
                  ref="image"
                  @load="getScaledSize"
                />
                <!-- 加载特效 -->
                <div class="loadSpecialEffects" v-if="loadImg"></div>
                <!-- 扫描脚框 -->
                <div class="top" v-if="loadImg"></div>
                <div class="bottom" v-if="loadImg"></div>

                <!-- 报错图片 -->
                <div class="errMain" v-if="errImg">
                  <div class="errImg">
                    <!-- <img src="../../assets/img/err.png"> -->
                  </div>
                  <div class="errText">
                    {{ errText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="paintingBrush">
            <div class="flex" style="column-gap: 20px">
              <div
                class="penClear"
                id="clean"
                :class="{ disable: cleanImgList.length == 1 }"
              >
                <img
                  type="disabled"
                  src="../../assets/img/1024/smear_withdraw.png"
                />
                <img
                  type="normal"
                  src="../../assets/img/1024/smear_withdraw_normal.png"
                />
                <img
                  type="hover"
                  src="../../assets/img/1024/smear_withdraw_hover.png"
                />
                <img
                  type="active"
                  src="../../assets/img/1024/smear_withdraw_active.png"
                />
              </div>
              <div
                class="penClear"
                :class="{ disable: !this.imageName }"
                @click="submitForm()"
              >
                <img
                  type="disabled"
                  src="../../assets/img/1024/smear_download.png"
                />
                <img
                  type="normal"
                  src="../../assets/img/1024/smear_download_normal.png"
                />
                <img
                  type="hover"
                  src="../../assets/img/1024/smear_download_hover.png"
                />
                <img
                  type="active"
                  src="../../assets/img/1024/smear_download_active.png"
                />
              </div>
            </div>
            <div class="paintingBrushSize">
              <div class="penSizePhone">
                <img src="../../assets/img/1024/drawpen.png" class="penSize" />
                画笔大小
              </div>
              <div class="range">
                <input
                  ref="inputRange"
                  type="range"
                  min="10"
                  max="70"
                  step="1"
                  class="custom-range"
                  id="customRange1"
                  style="width: 175px"
                  v-model="rangValue"
                  @input="handleInput"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="custom-cursor">
        <div id="move"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { logoSmear } from '@/api/aidata.js';
import Viewheader from '../../components/viewheader/viewheader.vue';
import ImgUpload from '@/components/secondPage/imgUpload1024.vue';
import '../../assets/css/imgPublic.css';
export default {
  name: 'smearCruch',
  components: {
    Viewheader,
    ImgUpload,
  },
  data() {
    return {
      titleIcon: require('../../assets/img/1024/smear_title.png'),
      listScrollTop: false,
      // 示例图列表
      exampleDiagram: [
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-1.jpeg',
        },
        //  {
        //   url: "https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-3.jpg"
        // },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl2.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl4.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl5.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl6.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl7.jpg',
        },
        {
          url: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl8.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/xxl-1.jpeg',
      sendisabled: true,
      limit: Number(1),
      fileList: [],
      // 加载时，禁止点击
      prohibitClicking: false,
      logoExample: true,

      errText: '',
      // 成功图
      successImg: true,
      // 失败图
      errImg: false,
      // 正在加载
      loadImg: false,
      // 加载文案
      errText: '处理中....',
      widtha: '',
      heighta: '',
      imageWidth: '',
      imageHeight: '',
      imageName: '',
      cleanImgList: [],

      rangValue: 40,
      isSample: '',
    };
  },
  watch: {
    rangValue: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          const rate = parseInt(((val - 10) / 60) * 100) + '%';
          this.$refs.inputRange.style.backgroundSize = `${rate} 100%`;
        });
      },
    },
  },
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, this.resultGraphImg);

    // 鼠标滑过事件 变换成圆圈
    const customCursor = document.getElementById('custom-cursor');
    const hoverContainer = document.querySelector('.hover-container');
    const updateCursorPosition = (event) => {
      customCursor.style.top = `${event.clientY - this.rangValue / 2}px`;
      customCursor.style.left = `${event.clientX - this.rangValue / 2}px`;
    };
    window.addEventListener('mousemove', (event) => {
      updateCursorPosition(event);
      if (hoverContainer.matches(':hover')) {
        customCursor.classList.add('zoom');
        $('#move').css({
          width: this.rangValue,
          height: this.rangValue,
          opacity: '1',
        });
        $('#custom-cursor').css({
          opacity: '1',
        });
      } else {
        customCursor.classList.remove('zoom');
        $('#move').css({
          opacity: '0',
        });
        $('#custom-cursor').css({
          opacity: '0',
        });
      }
    });
  },
  methods: {
    // 监听range事件
    handleInput(event) {
      this.rangValue = event.target.value;
    },
    // 点击选中高亮
    selectPicture(index, img) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;

      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = 0;
      canvas.height = 0;
      this.isSample = 0;
      this.imageName = '';

      // this.$nextTick(() => {
      // 获取原始图片宽高
      var imga = new Image();
      imga.src = img;
      imga.onload = () => {
        // 原始高度
        this.widtha = imga.width;
        this.heighta = imga.height;
        this.$nextTick(() => {
          this.canvas();
        });
      };
      // })

      // 图片队列
      this.cleanImgList = [];
      this.cleanImgList.push({ img: img });
      // console.log(this.cleanImgList)
    },

    // 获取缩小图片的宽度
    getScaledSize() {
      // 缩小后高度
      this.imageWidth = this.$refs.image.offsetWidth;
      this.imageHeight = this.$refs.image.offsetHeight;

      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = this.imageWidth;
      canvas.height = this.imageHeight;
    },
    // 成功回调
    getResultImg(response, file, fileList) {
      // 根据图片设置画板的宽度、高度
      var canvas = document.getElementById('canvas');
      // 画板大小
      canvas.width = 0;
      canvas.height = 0;
      if (response.state === '0x0000') {
        setTimeout(() => {
          // this.exampleDiagram = []
          // this.exampleDiagram = [{
          //   img: response.data
          // }]

          this.exampleDiagram.unshift({
            url: response.data.fileUrl,
          });
          this.imgCurrentActive = 0;
          this.listScrollTop = true;
          // console.log('exampleDiagram', this.exampleDiagram);
          this.imgCurrentActive = 0;
          this.resultGraphImg = response.data.fileUrl;

          // 成功图
          this.successImg = true;
          this.loadImg = false;
          this.errImg = false;
          // 加载时禁止点击切换
          this.prohibitClicking = true;
          this.imageName = '';
          // 获取原始图片宽高
          // this.$nextTick(() => {
          var imgb = new Image();
          imgb.src = response.data.fileUrl;
          imgb.onload = () => {
            // 原始高度
            this.widtha = imgb.width;
            // console.log(this.widtha)

            this.heighta = imgb.height;
            // console.log(this.heighta)

            this.canvas();
          };

          // 图片队列
          this.cleanImgList = [];
          this.cleanImgList.push({ img: response.data.fileUrl });
          // })
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          // 加载时禁止点击切换
          this.prohibitClicking = true;
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 图片队列
          this.cleanImgList = [];
        }, 1000);
      }
    },
    canvas() {
      var _this = this;
      var canvas = document.getElementById('canvas');
      // 设置背景图
      var context = canvas.getContext('2d');
      // range.oninput = function () {
      //     this.title = 'lineWidth: ' + this.value
      // }
      var Mouse = { x: 0, y: 0 };
      var lastMouse = { x: 0, y: 0 };
      var painting = false;

      // 获取缩小后的图片  大小
      this.getScaledSize();
      var widtha = this.widtha;
      var heighta = this.heighta;
      var imageWidth = this.imageWidth;
      var imageHeight = this.imageHeight;

      let offsetLeft = this.getElementOffset(canvas, 'offsetLeft');
      let offsetTop = this.getElementOffset(canvas, 'offsetTop');
      // console.log('offsetLeft', offsetLeft);
      // console.log('offsetTop', offsetTop);

      canvas.onmousemove = function (e) {
        // console.log("onmousemove")
        lastMouse.x = Mouse.x;
        lastMouse.y = Mouse.y;
        Mouse.x = e.pageX - offsetLeft;
        Mouse.y = e.pageY - offsetTop;

        // console.log(painting)
        if (painting) {
          // console.log("12")
          /*
            画笔参数：
                linewidth: 线宽
                lineJoin: 线条转角的样式, 'round': 转角是圆头
                lineCap: 线条端点的样式, 'round': 线的端点多出一个圆弧
                strokeStyle: 描边的样式, 'white': 设置描边为白色
            */
          // 设置画笔为圆形
          // context.lineCap = 'round'
          // context.lineJoin = "round";
          // console.log(range.value)

          // 线宽提示
          var range = document.getElementById('customRange1');
          // console.log(range.value)
          context.lineWidth = range.value == '0' ? '1' : range.value;
          context.lineJoin = 'round';
          context.lineCap = 'round';
          context.strokeStyle = 'white';

          // 开始绘画
          context.beginPath();

          context.moveTo(lastMouse.x, lastMouse.y);
          context.lineTo(Mouse.x, Mouse.y);
          context.closePath();
          context.stroke();
        }
      };

      canvas.onmouseup = function () {
        // 调用接口
        painting = false;
        // console.log(painting)
        // 原始图片
        let imgBase64 = '',
          imageUrl = '';
        if (
          _this.resultGraphImg.indexOf('data:') != -1 &&
          _this.resultGraphImg.indexOf('base64') != -1
        ) {
          imgBase64 = _this.resultGraphImg;
        } else {
          imageUrl = _this.resultGraphImg;
        }
        console.log('_this.resultGraphImg', _this.resultGraphImg);
        // console.log(imageUrl)
        // 原始宽度、高度
        // console.log('widtha', widtha);
        // console.log('heighta', heighta);
        // // 缩小后的高度、宽度

        //将掩码图   等比例放大 放大跟原图一样大
        var canvas1 = document.getElementById('canvas1');
        const ctx = canvas1.getContext('2d');
        const img1 = new Image();
        img1.src = canvas.toDataURL('image/png');
        var multipleWidth = widtha / imageWidth;
        var multipleHeight = heighta / imageHeight;
        // console.log('multipleWidth', multipleWidth);
        // console.log('multipleHeight', multipleHeight);

        img1.onload = () => {
          // canvas1.width = imageWidth * multipleWidth;
          // canvas1.height = imageHeight * multipleHeight;
          canvas1.width = widtha;
          canvas1.height = heighta;
          ctx.drawImage(img1, 0, 0, canvas1.width, canvas1.height);
          const maskUrl = canvas1.toDataURL('image/png');

          // console.log(maskUrl);
          // 如果需要下载生成的图片，可以创建一个链接并模拟点击下载
          // const link = document.createElement('a');
          // link.download = 'new-image.png';
          // link.href = maskUrl;
          // link.click();

          logoSmear({
            // 原图
            imageUrl,
            imgBase64,
            // 掩码图
            mask: canvas1.toDataURL('image/png'),
            isSample: _this.isSample,
          }).then(({ data }) => {
            if (data.state === '0x0000') {
              _this.resultGraphImg = data.data.base64;
              _this.imageName = data.data.imageName && data.data.maskName;
              // 图片队列
              _this.cleanImgList.push({
                img: data.data.base64,
                base64: data.data.base64,
              });
            } else if (data.state === '0x0008') {
              _this.$message({
                message: data.message,
                type: 'error',
                // offset: window.screen.height / 2
              });
              _this.$refs.navheader.logOn();
            } else {
              _this.$message({
                message: data.message,
                // type: "error",
                // offset: window.screen.height / 2
              });
            }
          });
        };
      };

      // 鼠标按下
      canvas.onmousedown = function () {
        painting = true;
        // var _this = this
        // _this.canvas()
        // console.log(painting)
      };

      // 清空画布  撤回操作
      var clean = document.getElementById('clean');
      clean.onclick = function () {
        if (_this.cleanImgList.length > 1) {
          // 删除最后一个元素
          _this.cleanImgList.splice(_this.cleanImgList.length - 1, 1);
          var cleanImgListIndex =
            _this.cleanImgList[_this.cleanImgList.length - 1].img;
          _this.resultGraphImg = cleanImgListIndex;
        }

        if (_this.cleanImgList.length < 2) {
          _this.imageName = '';
        }
      };
    },
    // 下载----------
    submitForm() {
      if (this.imageName) {
        const base64 = this.resultGraphImg; // 这里是base64字符串
        const blob = this.base64ToBlob(base64);
        this.downloadFile(blob, this.imageName.split('.')[0] + '.png');
      }
    },
    base64ToBlob(base64) {
      const parts = base64.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },

    downloadFile(file, filename) {
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    getElementOffset(element, direction) {
      let actualLeft = element[direction];
      let current = element.offsetParent;
      while (current !== null) {
        actualLeft += current[direction];
        current = current.offsetParent;
      }
      return actualLeft;
    },
    // 上传文件发生改变时
    handleChange(file, fileList) {
      // return;
      // console.log('fileList',this.fileList);
      // 加载时禁止点击切换
      this.prohibitClicking = true;
      // this.imgCurrentActive = 0;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.isSample = 1;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
}
.flex {
  display: flex;
}
.sc-img-upload {
  flex-basis: 130px;
  height: 100%;
  margin-right: 39px;
}
.sc-img-upload .img-upload {
  width: 100%;
  overflow: hidden;
}
.smearCruch {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: -80px;
  height: 100vh;
  overflow: hidden;
  padding: 100px 80px 40px 80px;
  box-sizing: border-box;
}

.smearCruch_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 0, 41, 1);
}
/* .smearCruch_bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
} */
.smearCruch_bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 44%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #100029 0%, rgba(16, 0, 41, 0) 100%);
}
.smearCruch_bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 56%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000, rgba(16, 0, 41, 0));
}

.smearCruch-wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  margin: auto;
  padding: 24px 40px 40px 40px;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0.64) 18%,
    rgba(255, 255, 255, 0.48) 80%
  );
  /* background: linear-gradient(112deg, #a3a3a3 18%, #7a7a7a 80%); */
  border-radius: 16px;
  color: #000;
  /* margin-top: 100px; */
  font-size: 16px;
  box-sizing: border-box;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.smearCruch-title {
  display: flex;
  font-size: 24px;
  color: #6c38e0;
  justify-content: space-between;
}
.smearCruch-cont {
  padding-top: 25px;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 70px);
}

/* // 鼠标滑过事件 变换成圆圈 */
body:hover #custom-cursor {
  opacity: 1;
}

body:hover .logoImg {
  cursor: none;
}
#custom-cursor {
  border-radius: 50%;
  background-color: #fff;
  position: fixed;
  top: 30px;
  left: 30px;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
#move {
  content: '';
  border-radius: 50%;
  border: 1px solid #6567e1;
  width: 60px;
  height: 60px;
  z-index: 999;
  opacity: 0;
}

.logoImg {
  flex: 1;
  /* width: 1050px; */
  /* height: 693px; */
  height: calc(100%);
  margin-left: 15px;
  margin-right: 15px;
  /* background-color: #fff; */
}
.logoImg > p {
  color: #5d5f6c;
  font-size: 16px;
  margin-bottom: 15px;
}
.smear-wrap {
  width: 100%;
  height: calc(100% - 37px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}

.logoImgLoad {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.logoImgResult {
  position: absolute;
  z-index: 1;
  /* width: 100%; */
  max-width: 100%;
  max-height: 100%;
}

.paintingBrush {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  cursor: pointer;
  padding-left: 40px;
  padding-top: 37px;
}

.paintingBrushSize {
  color: #333333;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.penClear {
  width: 56px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.penClear img {
  width: 20px;
  height: 20px;
  display: none;
}
.penClear img[type='normal'] {
  display: block;
}
.penClear.disable img {
  display: none;
}
.penClear.disable img[type='disabled'] {
  display: block;
}

.penClear:hover img {
  display: none;
}
.penClear:hover img[type='hover'] {
  display: block;
}
.penClear:active img {
  display: none;
}
.penClear:active img[type='active'] {
  display: block;
}
.penClear:active img[type='hover'] {
  display: none;
}

.penSize {
  /* margin-left: 20px; */
  margin-right: 5px;
}

.down_canvas {
  width: 122px;
  height: 46px;
  margin-left: 30px;
}

.logoUploadMain {
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 25px;
  display: flex;
}

.logoUpload {
  width: 158px;
  height: 157px;
}

.logoUpload .el-upload--picture-card {
  width: 158px;
  height: 157px;
  line-height: 157px;
}

.logoExample {
  display: flex;
}

.logoExampleMain {
  width: 158px;
  height: 157px;
  line-height: 157px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-left: 20px;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

.logoExampleMain img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.prohibitClicking {
  pointer-events: none;
}

#canvas {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable {
  pointer-events: none;
  cursor: default;
}

input[type='range'] {
  position: relative;
  -webkit-appearance: none; /*清除系统默认样式*/
  width: 100%;
  height: 3px; /*横条的高度*/
  border-radius: 10px;
  background: -webkit-linear-gradient(
      rgba(108, 56, 224, 1),
      rgba(108, 56, 224, 1)
    )
    no-repeat;
  background-size: 0% 100%;
}
input[type='range']::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(108, 56, 224, 0.3);
  border-radius: 10px;
}
/*拖动块的样式*/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /*清除系统默认样式*/
  height: 20px; /*拖动块高度*/
  width: 20px; /*拖动块宽度*/
  background: rgba(108, 56, 224, 1); /*拖动块背景*/
  border-radius: 50%; /*外观设置为圆形*/
  border: solid 1px rgba(108, 56, 224, 1); /*设置边框*/
}
input[type='range']::-webkit-slider-runnable-track {
  /*可以修改轨道的若干样式*/
  /* background: rgba(108, 56, 224, 1);设置左边颜色为#61bd12，右边颜色为#ddd */
}
.penSizePhone {
  display: block;
  font-size: 16px;
  color: #000108;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 41px;
}
.penSizePhone img {
  width: 22px;
  margin-right: 6px;
}
/* canvas:hover{
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Ccircle cx="15" cy="15" r="10" stroke="%23fff" stroke-width="1" fill="%23fff" /%3E%3C/svg%3E'), auto;
} */
.upload-title {
  display: flex;
  align-items: center;
}
.upload-title img {
  margin-right: 6px;
  width: 20px;
}
</style>
